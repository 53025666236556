import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link } from "gatsby"

import Obfuscate from "react-obfuscate"

const Legal = () => (
  <Layout>
    <SEO title="Pol&iacute;tica de Privacidad" />
    <section style={{ paddingTop: "16rem" }}>
      <div className="row m-10-hor">
        <div className="col-md-12 text-center">
          <h1>Pol&iacute;tica de Privacidad</h1>
        </div>
      </div>
    </section>
    <section className="legal">
      <div className="row m-10-hor">
        <article className="col-md-7 mx-auto legal">
          <p>
            Cerezo Rosa S.L. te informa sobre su Política de Privacidad respecto
            del tratamiento y protección de los datos de carácter personal de
            los usuarios que puedan ser recabados durante la navegación a través
            del sitio Web <Link to="/">https://cerezorosa.com</Link>.
          </p>

          <p>
            En este sentido, Cerezo Rosa S.L. garantiza el cumplimiento de la
            normativa vigente en materia de protección de datos personales,
            reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, de
            Protección de Datos Personales y de Garantía de Derechos Digitales
            (LOPD GDD). Cumple también con el Reglamento (UE) 2016/679 del
            Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a
            la protección de las personas físicas (RGPD).
          </p>

          <p>
            El uso de sitio Web implica la aceptación de esta Política de
            Privacidad así como las condiciones incluidas en el{" "}
            <Link href="/aviso-legal/">Aviso Legal</Link>.
          </p>

          <h2>Identidad del responsable</h2>
          <ul>
            <li>
              <b>Titular:</b>
              Cerezo Rosa S.L.
            </li>

            <li>
              <b>NIF:</b> B01962083
            </li>
            <li>
              <b>Domicilio:</b> Calle Marroquina 55, Madrid - España.
            </li>
            <li>
              <b>Correo electrónico:</b>{" "}
              <Obfuscate email="ruedaenrosa@cerezorosa.com" />
            </li>
            <li>
              <b>Sitio Web:</b> <Link to="/">https://cerezorosa.com</Link>
            </li>
          </ul>

          <h2>Principios aplicados en el tratamiento de datos</h2>
          <p>
            En el tratamiento de tus datos personales, el Titular aplicará los
            siguientes principios que se ajustan a las exigencias del nuevo
            reglamento europeo de protección de datos (RGPD):
          </p>

          <ul>
            <li>
              <b>Principio de licitud, lealtad y transparencia:</b> El Titular
              siempre requerirá el consentimiento para el tratamiento de los
              datos personales que puede ser para uno o varios fines específicos
              sobre los que el Titular informará al Usuario previamente con
              absoluta transparencia.
            </li>
            <li>
              <b>Principio de minimización de datos:</b> El Titular solicitará
              solo los datos estrictamente necesarios para el fin o los fines
              que los solicita.
            </li>
            <li>
              <b>Principio de limitación del plazo de conservación:</b> El
              Titular mantendrá los datos personales recabados durante el tiempo
              estrictamente necesario para el fin o los fines del tratamiento.
              El Titular informará al Usuario del plazo de conservación
              correspondiente{" "}
              <Link href="#finalidad-del-tratamiento-de-datos-personales">
                según la finalidad
              </Link>
              . En el caso de suscripciones, el Titular revisará periódicamente
              las listas y eliminará aquellos registros inactivos durante un
              tiempo considerable.
            </li>
            <li>
              <b>Principio de integridad y confidencialidad:</b> Los datos
              personales recabados serán tratados de tal manera que su
              seguridad, confidencialidad e integridad esté garantizada. El
              Titular toma las precauciones necesarias para evitar el acceso no
              autorizado o uso indebido de los datos de sus usuarios por parte
              de terceros.
            </li>
          </ul>

          <h2>Obtención de datos personales</h2>
          <p>
            Para navegar por <Link to="/">https://cerezorosa.com</Link> no es
            necesario que facilites ningún dato personal.
          </p>

          <h2>Derechos</h2>
          <p>
            El Titular te informa que sobre tus datos personales tienes derecho
            a:
          </p>
          <p></p>
          <ul>
            <li>Solicitar el acceso a los datos almacenados.</li>
            <li>Solicitar una rectificación o la cancelación.</li>
            <li>Solicitar la limitación de su tratamiento.</li>
            <li>Oponerte al tratamiento.</li>
          </ul>

          <p>No puedes ejercitar el derecho a la portabilidad de los datos.</p>

          <p>
            El ejercicio de estos derechos es personal y por tanto debe ser
            ejercido directamente por el interesado, solicitándolo directamente
            al Titular, lo que significa que cualquier cliente, suscriptor o
            colaborador que haya facilitado sus datos en algún momento, puede
            dirigirse a Cerezo Rosa S.L. y pedir información sobre los datos que
            tiene almacenados y cómo los ha obtenido, solicitar la rectificación
            de los mismos, oponerse al tratamiento, limitar su uso o solicitar
            la cancelación de esos datos en los ficheros del Titular.
          </p>
          <p>
            Para ejercitar tus derechos de acceso, rectificación, cancelación y
            oposición tienes que enviar un correo electrónico a{" "}
            <Obfuscate email="ruedaenrosa@cerezorosa.com" /> junto con la prueba
            válida en derecho como una fotocopia del D.N.I. o equivalente.
          </p>
          <p>
            Tienes derecho a la tutela judicial efectiva y a presentar una
            reclamación ante la autoridad de control, en este caso, la Agencia
            Española de Protección de Datos, si consideras que el tratamiento de
            datos personales que te conciernen infringe el Reglamento.
          </p>

          <h2 id="finalidad-del-tratamiento-de-datos-personales">
            Finalidad del tratamiento de datos personales
          </h2>
          <p>
            Cuando te conectas al sitio Web para mandar un correo al Titular, te
            suscribes a su boletín estás facilitando información de carácter
            personal de la que el responsable es Cerezo Rosa S.L.. Esta
            información puede incluir datos de carácter personal como pueden ser
            tu dirección IP, nombre y apellidos, dirección física, dirección de
            correo electrónico, número de teléfono, y otra información. Al
            facilitar esta información, das tu consentimiento para que tu
            información sea recopilada, utilizada, gestionada y almacenada por —
            DonDominio — sólo como se describe en el{" "}
            <Link to="/aviso-legal/">Aviso Legal</Link> y en la presente{" "}
            <Link href="/politica-privacidad/">Política de Privacidad</Link>.
          </p>
          <p>
            Los datos personales y la finalidad del tratamiento por parte del
            Titular es diferente según el sistema de captura de información:
          </p>

          <p>
            Existen otras finalidades por las que el Titular trata datos
            personales:
          </p>
          <ul>
            <li>
              Para garantizar el cumplimiento de las condiciones recogidas en el{" "}
              <Link to="/aviso-legal/">Aviso Legal</Link> y en la ley aplicable.
              Esto puede incluir el desarrollo de herramientas y algoritmos que
              ayuden a este sitio Web a garantizar la confidencialidad de los
              datos personales que recoge.
            </li>
            <li>
              Para apoyar y mejorar los servicios que ofrece este sitio Web.
            </li>
          </ul>

          <h2>Seguridad de los datos personales</h2>
          <p>
            Para proteger tus datos personales, el Titular toma todas las
            precauciones razonables y sigue las mejores prácticas de la
            industria para evitar su pérdida, mal uso, acceso indebido,
            divulgación, alteración o destrucción de los mismos.
          </p>
          <p>
            El sitio Web está alojado en DonDominio. La seguridad de los datos
            está garantizada, ya que toman todas las medidas de seguridad
            necesarias para ello. Puedes consultar su política de privacidad
            para tener más información.
          </p>

          <p>
            Cerezo Rosa S.L. informa al Usuario de que sus datos personales no
            serán cedidos a terceras organizaciones, con la salvedad de que
            dicha cesión de datos esté amparada en una obligación legal o cuando
            la prestación de un servicio implique la necesidad de una relación
            contractual con un encargado de tratamiento. En este último caso,
            solo se llevará a cabo la cesión de datos al tercero cuando Cerezo
            Rosa S.L. disponga del consentimiento expreso del Usuario.
          </p>
          <p>
            Sin embargo, en algunos casos se pueden realizar colaboraciones con
            otros profesionales, en esos casos, se requerirá consentimiento al
            Usuario informando sobre la identidad del colaborador y la finalidad
            de la colaboración. Siempre se realizará con los más estrictos
            estándares de seguridad.
          </p>

          <h2>Contenido de otros sitios web</h2>
          <p>
            Las páginas de este sitio Web pueden incluir contenido incrustado
            (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido
            incrustado de otras web se comporta exactamente de la misma manera
            que si hubieras visitado la otra web.
          </p>
          <p>
            Estos sitios Web pueden recopilar datos sobre ti, utilizar cookies,
            incrustar un código de seguimiento adicional de terceros, y
            supervisar tu interacción usando este código.
          </p>

          <h2>Legitimación para el tratamiento de datos</h2>
          <p>
            La base legal para el tratamiento de tus datos es: el
            consentimiento.
          </p>
          <p>
            Para contactar con el Titular, suscribirte a un boletín o realizar
            comentarios en este sitio Web tienes que aceptar la presente
            Política de Privacidad.
          </p>

          <h2>Categorías de datos personales</h2>
          <p>Las categorías de datos personales que trata el Titular son:</p>
          <ul>
            <li>Datos identificativos.</li>
            <li>No se tratan categorías de datos especialmente protegidos.</li>
          </ul>

          <h2>Conservación de datos personales</h2>
          <p>
            Los datos personales que proporciones al Titular se conservarán
            hasta que solicites su supresión.
          </p>

          <h2>Navegación Web</h2>
          <p>
            Al navegar por <Link to="/">https://cerezorosa.com</Link> se pueden
            recoger datos no identificativos, que pueden incluir, la dirección
            IP, geolocalización, un registro de cómo se utilizan los servicios y
            sitios, hábitos de navegación y otros datos que no pueden ser
            utilizados para identificarte.
          </p>

          <p>
            El sitio Web utiliza los siguientes servicios de análisis de
            terceros:
          </p>

          <p>
            El Titular utiliza la información obtenida para obtener datos
            estadísticos, analizar tendencias, administrar el sitio, estudiar
            patrones de navegación y para recopilar información demográfica.
          </p>
          <p>
            El Titular no se hace responsable del tratamiento de los datos
            personales que realicen las páginas web a las que puedas acceder a
            través de los distintos enlaces que contiene el sitio Web.
          </p>

          <h2>Exactitud y veracidad de los datos personales</h2>
          <p>
            Te comprometes a que los datos facilitados al Titular sean
            correctos, completos, exactos y vigentes, así como a mantenerlos
            debidamente actualizados.
          </p>
          <p>
            Como Usuario del sitio Web eres el único responsable de la veracidad
            y corrección de los datos remitidos al sitio Web exonerando a Cerezo
            Rosa S.L. de cualquier responsabilidad al respecto.
          </p>

          <h2>Aceptación y consentimiento</h2>
          <p>
            Como Usuario del sitio Web declaras haber sido informado de las
            condiciones sobre protección de datos de carácter personal, aceptas
            y consientes el tratamiento de los mismos por parte de Cerezo Rosa
            S.L. en la forma y para las finalidades indicadas en esta Política
            de Privacidad.
          </p>

          <h2>Revocabilidad</h2>
          <p>
            Para ejercitar tus derechos de acceso, rectificación, cancelación y
            oposición tienes que enviar un correo electrónico a{" "}
            <Obfuscate email="ruedaenrosa@cerezorosa.com" /> junto con la prueba
            válida en derecho como una fotocopia del D.N.I. o equivalente.
          </p>
          <p>
            El ejercicio de estos derechos no incluye ningún dato que Cerezo
            Rosa S.L. esté obligado a conservar con fines administrativos,
            legales o de seguridad.
          </p>

          <h2>Cambios en la Política de Privacidad</h2>
          <p>
            El Titular se reserva el derecho a modificar la presente Política de
            Privacidad para adaptarla a novedades legislativas o
            jurisprudenciales, así como a prácticas de la industria.
          </p>
          <p>
            Estas políticas estarán vigentes hasta que sean modificadas por
            otras debidamente publicadas.
          </p>
        </article>
      </div>
    </section>
  </Layout>
)

export default Legal
